<template>
  <div class="selectAttendant" @click.stop="PARENTCLICK">
    <template v-if="dataList.length > 0">
      <van-list
        v-model="loading"
        :finished="finished"
        :offset="500"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          class="content-li"
          v-for="(item, index) in dataList"
          :key="item.artificerId"
          @click.stop="clickAttendant(item)"
        >
          <img class="userImg" :src="item.avatar" alt="" />
          <div class="userInfo">
            <p>
              {{ item.nickName }}
              <!-- <span class="userState">可服务</span> -->
            </p>
            <p class="rate-p">
              <van-rate v-model="item.star" size="15" readonly color="orange" />
              {{ item.star }}
            </p>
            <p class="plane" @click="$router.push({path:'/serverMap', query: {obj:JSON.stringify(item)}})">
              <svg-icon icon-class="plane-2" class="planeIcon"></svg-icon>
              {{ item.distance }}km
            </p>
          </div>
          <div class="time-show-div">
            <p class="time-show" v-if="item.isOutOfRange != 1">
              <span>最早可约</span>
              <span>{{ getToDay(item.earliestAppointmentDate) }}
                {{ item.earliestAppointmentTime }}
              </span>
            </p>
            <p class="time-show" v-else>
              <span>超出范围</span>
            </p>
            <!-- <p class="drop" @click.stop="dropClick(index)"><span></span><span></span><span></span></p> -->
            <p
              class="popover-div"
              v-if="item.showPopover"
              @click.stop="unlikeAttendant(item, index)"
            >
              <svg-icon icon-class="unlike" class="unlikeIcon"></svg-icon
              ><span>不喜欢该技师</span>
            </p>
          </div>
        </div>
      </van-list>
    </template>
    <template v-else>
      <div class="nullDataDiv">
        <img src="../../assets/img/error.png" alt="" />
        <span class="text">暂无数据</span>
      </div>
    </template>
  </div>
</template>

<script>
import { chooseArtificer } from "@/api/service";
import { getToDay } from "@/utils/index";
import AMapLoader from "@amap/amap-jsapi-loader";
import Location from "@/utils/location";
export default {
  data() {
    return {
      page: 1,
      total: 0,
      lng: "",
      lat: "",
      loading: false,
      finished: false,
      loading: false,
      finished: false,
      dataList: []
    };
  },
  mounted() {
    if (this.$route.query.lat && this.$route.query.lng) {
      this.lat = this.$route.query.lat;
      this.lng = this.$route.query.lng;
      this.chooseArtificer();
    } else {
      this.getMap();
    }
  },
  methods: {
    // 点击选择技师
    clickAttendant(item) {
      // 如果超出了范围, 不能点击技师

      if (item.isOutOfRange == 1) {
        this.$toast("该技师已超出接单范围，请选择别的技师！");
        return false; 
      }

      sessionStorage.setItem("attendantInfo", JSON.stringify(item));
      this.$router.go(-1);
    },
    PARENTCLICK() {
      this.dataList.forEach(item => {
        item.showPopover = false;
      });
    },
    getToDay(str) {
      return getToDay(str);
    },
    dropClick(index) {
      this.dataList.forEach((item, ind) => {
        if (index == ind) {
          item.showPopover = true;
        } else {
          item.showPopover = false;
        }
      });
    },
    // 获取经纬度
    getMap() {
      let _this = this;
      AMapLoader.load({
        plugins: ["AMap.Geolocation"]
      }).then(AMap => {
        _this.$store.commit("loadingControl",true)
        Location.getCurrentLocation(
          AMap,
          result => {
            _this.lng = result.pois[0].location.lng;
            _this.lat = result.pois[0].location.lat;
            _this.chooseArtificer();
          },
          () => {
            console.log("定位失败");
            _this.chooseArtificer();
          }
        );
      });
    },
    // 获取数据
    chooseArtificer() {
      let data = {
        serveId: this.$route.query.serve_id,
        longitude: this.lng || "116.480073",
        latitude: this.lat || "39.99735",
        pageIndex: this.page,
        pageSize: 10
      };
      chooseArtificer(data).then(res => {
        this.loading = false;
        this.finished = false;
        this.total = res.data.total;
        res.data.records.forEach(item => (item.showPopover = false));
        this.dataList = this.dataList.concat(res.data.records);
        this.$store.commit("loadingControl",false)
      });
    },
    // 不喜欢技师
    unlikeAttendant(item, index) {
      this.PARENTCLICK();
    },
    // 分页
    onLoad() {
      if (this.page < Math.floor((this.total + 9) / 10)) {
        this.page++;
        console.log(22222)
        this.chooseArtificer();
      } else {
        this.finished = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.selectAttendant {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: rgba(100, 101, 102, 0.1);
  padding: 0.4rem;
  box-sizing: border-box;
  .content-li {
    display: flex;
    background: white;
    border-radius: 0.2rem;
    padding: 0.3rem;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 0.3rem;
    }
    .userImg {
      width: 2rem;
      height: 2rem;
      margin-right: 0.4rem;
    }
    .userInfo {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .plane {
        color: #1296db;
      }
      .name {
        font-weight: bold;
      }
    }
    .time-show-div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }
    .time-show {
      display: flex;
      flex-direction: column;
      font-size: 0.28rem;
      color: #eb4e52;
      border: solid 1px #f9caca;
      border-radius: 3px;
      span {
        padding: 2px 5px;
        &:first-child {
          background: #fbdddf;
        }
      }
    }
    .drop {
      display: flex;
      flex-direction: column;
      width: 0.5rem;
      align-items: center;

      span {
        width: 3px;
        height: 3px;
        background: #000;
        border-radius: 50%;
        &:not(:last-child) {
          margin-bottom: 3px;
        }
      }
    }
    .popover-div {
      position: absolute;
      right: 0.4rem;
      bottom: 0.2rem;
      background: rgb(228, 223, 223);
      font-size: 0.24rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.18rem 0.2rem;
      border-radius: 0.1rem;
      box-shadow: 1px 1px 1px 1px rgb(228, 223, 223);
      .unlikeIcon {
        font-size: 0.4rem;
        margin-right: 0.1rem;
      }
    }
  }
}
</style>
